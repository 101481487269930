@import "../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .action_button {
      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
        background-color: rgba(93, 108, 146, 0.35);
      }
    }

    .tab {
      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);
      }
    }

    .country_wrapper {
      &:focus {
        transform: scale(1.1);
      }
    }
  }
}

body:global(.low-performance) {
  .tab,
  .country_wrapper {
    transition: none !important;
  }
}

.container {
  padding: 1.6rem 1.6rem 1.6rem;
  margin: 0 auto;
  min-height: $content-min-height;

  @include media(">desktop720p") {
    padding: 0 3.4rem 1.6rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.2rem 0 2.4rem;

    @include media("<tablet") {
      margin: 0;
      display: grid;
      row-gap: 0.8rem;
      justify-content: center;
      margin-top: 0;
      display: flex;

      .country_wrapper {
        grid-row: -1;
      }
    }
  }
}
.stations_container {
  position: relative;
}
.tabs {
  display: flex;
  padding-left: $base-padding;
  padding-right: $base-padding;

  &_wrapper {
    overflow-x: auto;
    padding: 1.6rem 0;
  }

  @include media("<tablet") {
    justify-self: flex-start;
    padding-left: 0;
  }

  .tab {
    color: $color-text-gray;
    text-transform: uppercase;
    margin-right: 1.6rem;
    padding: 1.5rem 4.8rem;
    border-radius: $border-radius-tabs;
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 3px solid $color-text-gray;
    transition: 0.5s all;

    &.active {
      background-color: $color-primary;
      color: $color-light;
      border: 3px solid $color-primary;
    }

    @include media("<tablet") {
      padding: 0.8rem 1.6rem;
    }
  }
  .tab:last-child {
    margin-right: 0;
  }
}

.category_wrapper {
}

// ADS
.banner_ad_wrapper {
  max-width: 100vw;

  @include media("<tablet") {
    &:not(.podcast_banner_ad_wrapper) {
      .category_wrapper:first-child & {
        .banner_ad {
          transform: translateY(-0.5rem);
          margin: 2rem 0;
        }
      }
    }
  }
  .banner_ad {
    max-width: 100%;
    transform: translateY(-2rem);
    margin: 2rem 1.6rem;

    // TEST ADS
    & > div {
      margin-left: 0 !important;
    }

    @include media("<tablet") {
      overflow: hidden;
    }

    &:empty {
      margin: 0;
    }
  }
}
.podcast_banner_ad_wrapper {
  .banner_ad {
    transform: none;
    margin: 2rem 1.6rem;

    // TEST ADS
    & > div {
      margin-left: 0 !important;
    }
  }
}

html[dir="rtl"] {
  .tab:first-child {
    margin-right: 0;
  }
  .tab:last-child {
    margin-right: 1.6rem;
  }
}

.country_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s all;

  color: rgba($color-light, 0.6);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 2.8rem;
  text-transform: uppercase;
  
  @include media("<=tablet") {
    font-size: 2rem;
  }
  @include media("<=phoneXL") {
    font-size: 1.8rem;
  }

  .country_name {
    font-size: 2.4rem;
    color: rgba($color-light, 0.5);
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;

    & > span {
      display: block;
      margin-right: -0.8rem;
    }

    @include media("<desktop720p") {
      display: none;
    }
  }

  .country_logo {
    margin-right: 1.6rem;
    background-image: var(--image-background);
    width: 3.2rem;
    height: 3.2rem;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-size: 102%;
    border-radius: 100%;
    background-position: center;

    html[dir="rtl"] & {
      margin-right: 1.6rem;
    }

    @include media("<=phoneXL") {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  padding-top: 9.6rem;

  svg {
    width: 7.2rem;
    height: 7.2rem;
  }
}
.podcasts_tab {
  position: relative;
  padding-top: 2.4rem;

  .category_section {
    margin-bottom: 1rem;

    h2 + div {
      margin-top: 0;
    }
  }
}

.stations_wrapper {
  margin-bottom: 5.6rem;
}
.categories_wrapper {
  margin-bottom: 9.4rem;
}
